import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import logon from '../views/logon.vue'
const routes = [
  {
    path: '/',
    name: 'logon',
    component: logon
  },
  {
    path: '/comprehensive',
    name: 'comprehensive',
    component: () => import('../views/comprehensive.vue')
  },
  {
    path: '/teacher',
    name: 'teacher',
    component: () => import('../views/teacher.vue')
  },
  {
    path: '/decimal',
    name: 'decimal',
    component: () => import('../views/decimal.vue')
  },
  {
    path: '/Grade',
    name: 'Grade',
    component: () => import('../views/Grade.vue')
  },
  {
    path: '/Expansion',
    name: 'Expansion',
    component: () => import('../views/Expansion.vue')
  },
  {
    path: '/division',
    name: 'division',
    component: () => import('../views/division.vue')
  },
  {
    path: '/multiplication',
    name: 'multiplication',
    component: () => import('../views/multiplication.vue')
  },
  {
    path: '/Basics',
    name: 'Basics',
    component: () => import('../views/Basics.vue')
  },
  {
    path: '/Fallibility',
    name: 'Fallibility',
    component: () => import('../views/Fallibility.vue')
  },
  {
    path: '/abacus',
    name: 'abacus',
    component: () => import('../views/abacus.vue')
  },
  {
    path: '/print', //加减算打印
    name: 'print',
    component: () => import('../views/print.vue')
  },
  {
    path: '/gradePrint', //等级鉴定 珠算1-10打印
    name: 'gradePrint',
    component: () => import('../views/gradePrint.vue')
  },
  {
    path: '/lightningPrint', //等级鉴定 心算1-10打印
    name: 'lightningPrint',
    component: () => import('../views/lightningPrint.vue')
  },
  {
    path: '/pearlLevelPrint', //等级鉴定 珠算段位级
    name: 'pearlLevelPrint',
    component: () => import('../views/pearlLevelPrint.vue')
  },
  {
    path: '/heartLevelPrint', //等级鉴定 心算段位级
    name: 'heartLevelPrint',
    component: () => import('../views/heartLevelPrint.vue')
  },
  {
    path: '/ridePrint', //乘法打印
    name: 'ridePrint',
    component: () => import('../views/ridePrint.vue')
  },
  {
    path: '/rideDislocationPrint', //乘法错位打印
    name: 'rideDislocationPrint',
    component: () => import('../views/rideDislocationPrint.vue')
  },
  {
    path: '/promoteRidePrint', //乘法提速打印
    name: 'promoteRidePrint',
    component: () => import('../views/promoteRidePrint.vue')
  },
  {
    path: '/divisionDislocationPrint', //除法错位打印
    name: 'divisionDislocationPrint',
    component: () => import('../views/divisionDislocationPrint.vue')
  },
  {
    path: '/promoteDivisionPrint', //除法提速打印
    name: 'promoteDivisionPrint',
    component: () => import('../views/promoteDivisionPrint.vue')
  },
  {
    path: '/divisionPrint', //除法打印
    name: 'divisionPrint',
    component: () => import('../views/divisionPrint.vue')
  },
  {
    path: '/decimalPrint', //小数加减算打印
    name: 'decimalPrint',
    component: () => import('../views/decimalPrint.vue')
  },
  {
    path: '/abacusPrint', //珠算打印
    name: 'abacusPrint',
    component: () => import('../views/abacusPrint.vue')
  },
  {
    path: '/paintingabacusPrint', //看数画珠
    name: 'paintingabacusPrint',
    component: () => import('../views/paintingabacusPrint.vue')
  },
  {
    path: '/abacusNumberPrint', //珠像加数字
    name: 'abacusNumberPrint',
    component: () => import('../views/abacusNumberPrint.vue')
  },
  {
    path: '/writenumberPrint', //看数写数
    name: 'writenumberPrint',
    component: () => import('../views/writenumberPrint.vue')
  },
  {
    path: '/abaucsBasicPrint', //看题画珠 加减算
    name: 'abaucsBasicPrint',
    component: () => import('../views/abaucsBasicPrint.vue')
  },
  {
    path: '/abaucsRidePrint', //看题画珠 乘法
    name: 'abaucsRidePrint',
    component: () => import('../views/abaucsRidePrint.vue')
  },
  {
    path: '/abaucsDivisionPrint', //看题画珠 除法
    name: 'abaucsDivisionPrint',
    component: () => import('../views/abaucsDivisionPrint.vue')
  },
  {
    path: '/rideAndDivisionPrint', // 乘除法综合打印
    name: 'rideAndDivisionPrint',
    component: () => import('../views/rideAndDivisionPrint.vue')
  },
]

const router = createRouter({
  // base: '/dist',
  // mode: 'hash',
  history: createWebHashHistory(),
  routes
})
export default router
