<template>
	<!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/comprehensive">comprehensive</router-link>
  </nav> -->
	<router-view />
</template>
<style lang="less">
.bth_hover {
	&:hover {
		cursor: pointer;
		transform: scale(1.1);
		animation: all 0.5 ease-in-out;
	}
}
</style>
