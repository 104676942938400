import axios from 'axios';
import api from './axios.js';
import router from '../router/index.js';
// axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
// axios.defaults.headers["Content-Type"] = "multipart/form-data";
import qs from 'qs';

//登录
function Login(data) {
	console.log(data);
	let params = qs.stringify(data);
	return api.POST('http://120.24.30.170:9797/web/login', params).then((res) => {
		return res;
	});
}
function getUserDetail(data) {
	let params = qs.stringify(data);
	return api.GET('http://120.24.30.170:9797/web/user/info', params).then((res) => {
		return res;
	});
}
//注册
function register(data) {
	let params = qs.stringify(data);
	return api.POST('http://120.24.30.170:9797/web/login/register', params).then((res) => {
		return res;
	});
}
//修改密码
function changePWD(data) {
	let params = qs.stringify(data);
	return api.POST('http://120.24.30.170:9797/web/login/update', params).then((res) => {
		return res;
	});
}
// 获取短信验证码
function getCode(data) {
	let params = qs.stringify(data);
	return api.POST('http://120.24.30.170:9797/web/login/sms/send', params).then((res) => {
		return res;
	});
}
// 生成二维码
function setAnswer(data) {
	let params = qs.stringify(data);
	return api.POST('http://120.24.30.170:9797/web/set/answer', params).then((res) => {
		return res;
	});
}
function getStorehouse(data) {
	// let params = qs.stringify(data);
	return api.GET('http://120.24.30.170:9797/web/get/questions/list', data).then((res) => {
		return res;
	});
}
function saveStorehouse(data) {
	let params = qs.stringify(data);
	return api.POST('http://120.24.30.170:9797/web/set/questions', params).then((res) => {
		return res;
	});
}
function deleteStorehouse(data) {
	let params = qs.stringify(data);
	return api.Delete('http://120.24.30.170:9797/web/delete/questions', params).then((res) => {
		return res;
	});
}

export default {
	Login,
	getUserDetail,
	changePWD,
	register,
	getCode,
	setAnswer,
	getStorehouse,
	saveStorehouse,
	deleteStorehouse,
};
