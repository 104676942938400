import { createStore } from 'vuex'

export default createStore({
  state: {
    Navigation: 0,
    quantity: {
      max: null,
      min: null,
      strokes: null
    },
    Print: null,
    generateData: [],//生成数据
    appendData: [], //添加试题数据
  },
  getters: {
  },
  mutations: {
    GetappendData(state, newValue) {
      state.appendData = newValue;
    },
    GetgenerateData(state, newValue) {
      state.generateData = newValue;
    },
    GetNavigation(state, newValue) {
      state.Navigation = newValue
    },
    Getquantity(state, newValue) {
      state.quantity = newValue
    },
    GetPrint(state, newValue) {
      state.Print = newValue
    },
  },
  actions: {
    SetappendData(context, value) {
      context.commit('GetappendData', value)
    },
    SetgenerateData(context, value) {
      context.commit('GetgenerateData', value)
    },
    SetNavigation(context, value) {
      context.commit('GetNavigation', value)
    },
    Setquantity(context, value) {
      context.commit('Getquantity', value)
    },
    SetPrint(context, value) {
      context.commit('Print', value)
    }
  },
  modules: {
  }
})
