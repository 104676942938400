import axios from 'axios';
import { ElMessage, ElMessageBox, ElLoading } from 'element-plus';
import { nextTick } from 'vue';
// baseURL: 'http://192.168.0.171:9797',
//120.24.30.170:9797
let baseURL = '';
// 设置超时
axios.defaults.timeout = 8000;
// 请求头
// axios.defaults.headers["Content-Type"] = "multipart/form-data";
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';

let loadingInstance = null;

let POST = function (url, params) {
	const URL = baseURL + url;
	console.log(window.localStorage.getItem('token'));

	if (window.localStorage.getItem('token')) {
		axios.defaults.headers['AdminAuthorization'] = window.localStorage.getItem('token');
	} else {
		axios.defaults.headers['AdminAuthorization'] = '';
	}
	return axios.post(URL, params).then((res) => {
		return res.data;
	});
};

export function GET(url, params) {
	console.log(params, '**--**');
	// const URL = url;
	const URL = baseURL + url;
	if (window.localStorage.getItem('token')) {
		axios.defaults.headers['AdminAuthorization'] = window.localStorage.getItem('token');
	} else {
		axios.defaults.headers['AdminAuthorization'] = '';
	}
	// axios.defaults.headers["Content-Type"] = "application/json";

	return axios.get(URL, { params: params }).then((res) => {
		if (res.data.code != 200) {
			ElMessage(res.data.msg);
		} else {
			return res.data;
		}
	});
}
export function Delete(url, params) {
	console.log(params, '**--**');
	// const URL = url;
	const URL = baseURL + url;
	if (window.localStorage.getItem('token')) {
		axios.defaults.headers['AdminAuthorization'] = window.localStorage.getItem('token');
	} else {
		axios.defaults.headers['AdminAuthorization'] = '';
	}
	// axios.defaults.headers["Content-Type"] = "application/json";
	// { params: params }
	return axios.delete(URL, { data: params }).then((res) => {
		if (res.data.code != 200) {
			ElMessage(res.data.msg);
		} else {
			return res.data;
		}
	});
}
axios.interceptors.request.use((config) => {
	loadingInstance = ElLoading.service();
	return config;
});
axios.interceptors.response.use(
	(response) => {
		// 对响应数据做点什么
		nextTick(() => {
			// Loading should be closed asynchronously
			loadingInstance.close();
		});
		const res = response.data;
		if (res.code && res.code !== 200) {
			// `token` 过期或者账号已在别处登录
			if (res.code === 403) {
				window.localStorage.clear();
				ElMessageBox.alert('登录状态失效，请点击确认重新登录', '提示', {})
					.then((res) => {
						window.location.href = '/'; // 去登录页
					})
					.catch((err) => {
						console.log(err);
					});
			} else if (res.code === 410) {
				window.localStorage.clear();
				// ElMessageBox.alert(res.msg, '提示', {})
				//   .then((res) => {
				//     window.location.href = '/'; // 去登录页
				//   })
				//   .catch((err) => {
				//     console.log(err)
				//   });
			} else {
				ElMessage.error(res.msg);
			}
			return Promise.reject(res);
		} else {
			return response;
		}
	},
	(error) => {
		nextTick(() => {
			// Loading should be closed asynchronously
			loadingInstance.close();
		});
		return Promise.reject(error);
	}
);

export default {
	GET,
	POST,
	Delete,
};
