<template>
	<div class="content" style="">
		<img class="qrcode" src="../img/ZXS503.jpg" alt="" />
		<div class="panel" v-show="type == null">
			<div class="title">账号登录</div>
			<div class="input">
				<div>
					<input type="text" placeholder="输入账号" v-model="mapStr.login_name" @keyup.enter="Sign('')" />
					<img src="../img/logon/login_icon_profile.png" />
					<img src="../img/logon/login_icon_close.png" @click="mapStr.login_name = ''" />
				</div>

				<div>
					<input :type="pswtype" placeholder="输入密码" v-model="mapStr.login_pwd" @keyup.enter="Sign('')" />
					<img src="../img/logon/login_icon_password.png" />
					<img src="../img/logon/login_icon_not_see.png" v-if="pswtype == 'password'" @click="pswtype = 'text'" />
					<img src="../img/logon/login_icon_see.png" v-if="pswtype == 'text'" @click="pswtype = 'password'" />
				</div>

				<!-- <div class="code">
          <div class="code-inp">
            <input type="text" placeholder="图形验证码" v-model="mapStr.login_code" />
            <img src="../img/logon/login_icon_4.png" />
          </div>
          <div class="codenum">
            <img src="http://120.24.30.170/ZhuXinSuan_Admin/rand_getCode.do" />
          </div>
        </div> -->
			</div>
			<p class="accounts">
				<span style="display: flex; align-items: center" class="bth_hover" @click="keepUser">
					<img src="@/img/login_choice1.png" style="width: 0.8vw; height: 0.8vw; cursor: pointer; margin-right: 0.5rem; display: block" v-if="isKeep" />
					<img src="@/img/login_choice2.png" style="width: 0.8vw; height: 0.8vw; cursor: pointer; margin-right: 0.5rem; display: block" v-else />
					<span>记住密码</span>
				</span>
				<span @click="operation(1)">修改密码</span>
			</p>
			<p class="accounts">
				<span style="display: flex; align-items: center" class="bth_hover" @click="autoUser">
					<img src="@/img/login_choice1.png" style="width: 0.8vw; height: 0.8vw; cursor: pointer; margin-right: 0.5rem; display: block" v-if="isAuto" />
					<img src="@/img/login_choice2.png" style="width: 0.8vw; height: 0.8vw; cursor: pointer; margin-right: 0.5rem; display: block" v-else />
					<span>自动登录</span>
				</span>
				<!-- <span @click="operation(0)">注册账号</span> -->
			</p>

			<!-- 按钮 -->
			<div class="login bth_hover" @click="Sign('')">登陆</div>
		</div>

		<!-- 弹框 -->
		<div class="popup" v-if="type != null">
			<div class="modular">
				<p class="title">{{ type == 0 ? '注册账号' : '修改密码' }}</p>
				<div class="data">
					<div v-if="type == 0">
						<el-cascader v-model="citys" :options="options" :props="props" placeholder="请选择省市区" clearable />
						<span>省市区</span>
					</div>
					<div>
						<input type="number" placeholder="请输入手机号码" v-model="phoneNumber" />
						<span>手机号码</span>
						<span class="code bth_hover" @click="getCode">{{ isGetCode ? codeTime + '后重新获取' : '获取验证码' }} </span>
					</div>
					<div>
						<input type="text" placeholder="请输入验证码" v-model="code" />
						<span>验证码</span>
					</div>
					<div>
						<input type="password" placeholder="请设置密码（6数字组合）" v-model="pwd" />
						<span>设置密码</span>
					</div>
					<div>
						<input type="password" placeholder="请再次输入密码" v-model="passWord" />
						<span>确认密码</span>
					</div>
				</div>

				<div class="button">
					<p @click="register">确定</p>
					<p @click="type = null">取消</p>
				</div>
			</div>
		</div>

		<section class="footer-main">
			<a href="javascript:void(0)" @click="toBeian()">粤ICP备2022030527号-1</a>
		</section>
		<template v-if="errorMessage">
			<el-dialog v-model="errorDialog" title="登录提示" width="30%" destroy-on-close center>
				<el-result title="账号过期" :sub-title="errorMessage.msg">
					<template #icon>
						<img src="../img/ZXS503.jpg" alt="" />
					</template>
					<template #extra>
						<el-button type="primary" @click="errorDialog = false">我知道了</el-button>
					</template>
				</el-result>
			</el-dialog>
		</template>
	</div>
</template>

<script>
import { ref, reactive, onMounted, toRefs } from 'vue';
import publicapi from '@/js/public.js';
import router from '../router/index.js';
import { ElMessage } from 'element-plus';
import cityData from '@/js/city.js';

export default {
	name: 'logon',
	setup() {
		let type = ref(null);
		function operation(val) {
			type.value = val;
		}

		const state = reactive({
			isGetCode: false,
			codeTime: 60,
			phoneNumber: '', //手机号
			code: '', //验证码
			pwd: '',
			passWord: '',
			citys: [], //城市数据
			props: {
				expandTrigger: 'hover',
				value: 'name',
				label: 'name',
				children: 'children',
			},
			errorMessage: null,
			errorDialog: false,
			options: cityData,
			isKeep: false, //记住密码
			isAuto: false, //自动登录
		});
		// 登录
		let pswtype = ref('password');
		let mapStr = reactive({
			login_name: '',
			login_pwd: '',
			login_code: '',
			login_type: '0',
		});
		function toBeian() {
			window.open('https://beian.miit.gov.cn/#/Integrated/index');
		}
		function getCode() {
			if (state.phoneNumber === '') {
				ElMessage.error('请先输入手机号');
				return;
			}
			if (!/^1[3|4|5|7|8][0-9]\d{4,8}$/.test(state.phoneNumber)) {
				ElMessage.error('请输入正确的手机号');
				return;
			}
			if (state.isGetCode) {
				ElMessage.warning('验证码已经发送，休息一下吧');
				return;
			}
			publicapi
				.getCode({
					phone: state.phoneNumber,
				})
				.then(() => {
					state.isGetCode = true;
					const setTimeOut = setInterval(() => {
						--state.codeTime;
						if (state.codeTime === 0) {
							state.isGetCode = false;
							clearInterval(setTimeOut);
						}
					}, 1000);
				});
		}
		function register() {
			console.log(type.value);
			if (type.value === 0) {
				if (state.citys.length === 0) {
					ElMessage.error('请先选择省市区');
					return;
				}
			}
			if (state.phoneNumber === '') {
				ElMessage.error('请先输入手机号');
				return;
			}
			if (state.code === '') {
				ElMessage.error('请先输入短信验证码');
				return;
			}
			if (state.pwd === '') {
				ElMessage.error('请先输入密码');
				return;
			}
			if (state.passWord === '') {
				ElMessage.error('请再次输入密码');
				return;
			}
			if (state.pwd !== state.passWord) {
				ElMessage.error('两次输入密码互不对应');
				return;
			}
			if (!/^\d{6}$/.test(state.pwd)) {
				ElMessage.error('密码需6位数字');
				return;
			}
			if (type.value === 0) {
				publicapi
					.register({
						role: state.citys.toString(),
						username: state.phoneNumber,
						code: state.code,
						password: state.passWord,
					})
					.then((res) => {
						console.log(res);
						if (res.code === 500) {
							ElMessage.error(res.msg);
						} else {
							ElMessage.success('注册成功');
							Sign({ phoneNumber: state.phoneNumber, passWord: state.passWord });
						}
					});
			}
			if (type.value === 1) {
				publicapi
					.changePWD({
						username: state.phoneNumber,
						code: state.code,
						password: state.passWord,
					})
					.then((res) => {
						console.log(res);
						if (res.code === 500) {
							ElMessage.error(res.msg);
						} else {
							ElMessage.success('修改成功');
							Sign({ phoneNumber: state.phoneNumber, passWord: state.passWord });
						}
					});
			}
		}
		function Sign(data) {
			let that = this;
			if (mapStr.login_name === '' && !data) {
				ElMessage.error('请先输入用户名');
				return;
			}
			if (mapStr.login_pwd === '' && !data) {
				ElMessage.error('请先输入密码');
				return;
			}
			window.localStorage.removeItem('token');
			publicapi
				.Login({
					username: data ? data.phoneNumber : mapStr.login_name,
					password: data ? data.passWord : mapStr.login_pwd,
				})
				.then((res) => {
					window.localStorage.setItem('token', res.data);
					publicapi.getUserDetail().then((res) => {
						console.log(res);
						let userData = res.data;
						let oneRouter = userData.permissions.filter((item) => item.indexOf('/') > -1);
						let twoRouter = userData.permissions.filter((item) => item.indexOf('/') == -1);
						console.log(oneRouter);
						if (oneRouter && oneRouter.length === 0) {
							ElMessage.warning('账号暂无角色权限，请联系管理员添加权限');
							state.errorMessage = '账号暂无角色权限，请联系管理员添加权限';
							state.errorDialog = true;
							return;
						}
						userData.oneRouter = oneRouter;
						userData.twoRouter = twoRouter;
						window.localStorage.setItem('userData', JSON.stringify(userData));
						ElMessage.success('登录成功');
						let keepData;
						if (state.isAuto) {
							keepData = {
								username: data ? data.phoneNumber : mapStr.login_name,
								password: data ? data.passWord : mapStr.login_pwd,
								isAuto: state.isAuto,
							};
						}
						if (state.isKeep) {
							keepData = {
								username: data ? data.phoneNumber : mapStr.login_name,
								password: data ? data.passWord : mapStr.login_pwd,
								isKeep: state.isKeep,
							};
						}
						if (keepData) {
							window.localStorage.setItem('keepData', JSON.stringify(keepData));
						}
						setTimeout(() => {
							router.push(oneRouter[0]);
						}, 1300);
					});
				})
				.catch((error) => {
					if (error.code === 410) {
						state.errorMessage = error;
						state.errorDialog = true;
						console.log(state.errorMessage);
					}
				});
		}
		const autoUser = () => {
			state.isAuto = !state.isAuto;
		};
		const keepUser = () => {
			state.isKeep = !state.isKeep;
		};
		onMounted(() => {
			window.localStorage.removeItem('token');
			let keepData;
			if (window.localStorage.getItem('keepData') !== 'undefined') {
				keepData = JSON.parse(window.localStorage.getItem('keepData'));
			} else {
				window.sessionStorage.removeItem('keepData');
				window.localStorage.removeItem('userData');
			}
			if (keepData) {
				console.log(keepData);
				mapStr.login_name = keepData.username;
				mapStr.login_pwd = keepData.password;
				state.isKeep = true;
				if (keepData.isAuto) {
					state.isAuto = true;
					Sign({ phoneNumber: keepData.username, passWord: keepData.password });
				}
			} else {
				window.localStorage.removeItem('keepData');
				window.localStorage.removeItem('userData');
			}
		});
		return {
			toBeian,
			keepUser,
			autoUser,
			register,
			getCode,
			type,
			operation,
			mapStr,
			Sign,
			pswtype,
			...toRefs(state),
		};
	},
};
</script>

<style scoped lang="less">
@import '../css/css.less';
@import '../css/logon.less';
.footer-main {
	width: 100vw;
	height: 44px;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.2vw;
	color: #1589cc !important;
	a {
		color: #1589cc !important;
		text-decoration-line: unset;
	}
}
.qrcode {
	width: 6vw;
	height: 6vw;
	position: absolute;
	top: 1vw;
	right: 1vw;
}
::v-deep .el-cascader {
	width: 40rem;
	height: 4rem;
	font-weight: bold;
	color: white;
	background: #1589cc;
	border-radius: 1rem;
	margin-left: 3rem;
	box-sizing: border-box;
	border: none !important;
}
::v-deep .el-input__inner {
	background: transparent;
	border: none;
	box-shadow: none;
	color: white !important;
}
::v-deep .el-input el-input--default el-input--suffix {
	width: 100%;
	height: 100%;
	border: none !important;
}
::v-deep .el-cascader .el-input {
	width: 100%;
	height: 100%;
	border: none !important;
}
::v-deep .el-cascader .el-input .el-input__inner {
	width: 100%;
	height: 100%;
	border: none !important;
	color: white;
}
::v-deep .el-cascader .el-input.is-focus .el-input__inner {
	box-shadow: unset !important;
}
::v-deep .el-cascader .el-input .el-input__inner:focus {
	box-shadow: unset !important;
}
::v-deep .el-input__inner::placeholder {
	color: #a1d0eb !important;
}
</style>
