import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "./css/public.css";
import "./js/rem.js";

let Height = document.body.offsetHeight;
window.onresize = function () {
  document.body.style.height = Height + 'px';
};
createApp(App).use(store).use(router).use(ElementPlus).mount('#app')
